<div class="masterhead">
  <div class="ui container">
    <div class="ui fluid centered circular image">
      <img src="/assets/imagens/avatar/avatar.png" />
    </div>
    <div class="ui centered header">
      <div class="content">CASSIO FACHINELLI</div>
      <div class="sub header">WEB e Mobile Developer</div>
    </div>
  </div>
</div>