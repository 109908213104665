<section class="projetos">
  <header class="ui dividing header">Projetos realizados:</header>
  <div class="ui stackable grid">
    <div class="ui row">
      <div
        class="ui tablet only computer only middle aligned seven wide column"
      >
        <img
          alt="Logo do DRH"
          class="ui centered image"
          src="/assets/imagens/projetos/drh-roxo.png"
        />
      </div>
      <div class="ui nine wide column" id="instagram-extractor">
        <div class="ui header">Dedicando-se às Relações Humanas</div>
        <p>
          A Dedicando-se às Relações Humanas é uma empresa de RH que busca
          entender o propósito dos candidatos, aliando às demandas do mercado de
          trabalho.<br />Implementado em Angular v14.
        </p>
        <p>
          <span class="bold">Saiba mais em:</span>
          <a target="_blank" href="https://drhrs.com.br/">&nbsp;drhs.com.br</a>.
        </p>
      </div>
    </div>
    <div class="ui row">
      <div class="ui nine wide column">
        <div class="ui header">Dimenor Fichas</div>
        <p>
          Sistema Integrado de Gestão foi desenvolvido sob medida para a
          administração de compra e venda de créditos para jogadores de Poker
          online.<br />Implementado em Angular13 & NestJS.
        </p>
        <p>
          <span class="bold">Saiba mais em:</span>
          <a target="_blank" href="https://dimenor.net/">&nbsp;dimenor.net</a>.
        </p>
      </div>
      <div
        class="ui tablet only computer only middle aligned seven wide column"
      >
        <img
          alt="Logo do Dimenor"
          class="ui medium centered image"
          src="/assets/imagens/projetos/dimenor.png"
        />
      </div>
    </div>
    <div class="ui row">
      <div
        class="ui tablet only computer only middle aligned seven wide column"
      >
        <img
          alt="Logo do Decisão RH"
          class="ui small centered image"
          src="/assets/imagens/projetos/drh.png"
        />
      </div>
      <div class="ui nine wide column" id="instagram-extractor">
        <div class="ui header">Decisão RH</div>
        <p>
          A DRH é uma empresa que atua na área de prestação de serviços em
          recursos humanos. Foi desenvolvido o sistema e o site deste escritório
          de RH que é referência na área do Vale dos Sinos.<br />Implementado em
          Angular + MySQL + Lumen Framework.
        </p>
        <p>
          <span class="bold">Saiba mais em:</span>
          <a target="_blank" href="https://decisaorh.com.br"
            >&nbsp;decisaorh.com.br</a
          >.
        </p>
      </div>
    </div>
    <div class="ui row">
      <div class="ui nine wide column" id="brpc">
        <div class="ui header">PioTools</div>
        <p>
          PioTools é uma ferramenta auxiliar desenvolvida exclusivamente para o
          BRPC gerar scripts e reports do software PioSolver de forma automática
          e em lotes.<br />Implementado em C#.
        </p>
        <p>
          <span class="bold">Saiba mais em:</span>
          <a target="_blank" href="https://brpokercrew.com.br"
            >&nbsp;brpokercrew.com.br</a
          >.
        </p>
      </div>
      <div
        class="ui tablet only computer only middle aligned seven wide column"
      >
        <img
          alt="Logo do BRPC"
          class="ui medium centered image"
          src="/assets/imagens/projetos/brpc.jpg"
        />
      </div>
    </div>
    <div class="ui row">
      <div
        class="ui tablet only computer only middle aligned seven wide column"
      >
        <img
          alt="Logo do Clio App - Instagram Extractor"
          class="ui medium centered image"
          src="/assets/imagens/projetos/clio-app.png "
        />
      </div>
      <div class="ui nine wide column" id="instagram-extractor">
        <div class="ui header">Clio App - Instagram Extractor</div>
        <p>
          O Clio App – Instagram Extractor é uma ferramenta - baseada na API do
          Instagram - que extrai para você os dados públicos dos usuários do seu
          público-alvo como, por exemplo, o nome, email, WhatsApp, cidade,
          estado, sexo.<br />
          Implementado em C#.
        </p>
        <p>
          <span class="bold">Saiba mais em:</span>
          <a target="_blank" href="https://clioapp.com.br/instagram/"
            >&nbsp;clioapp.com.br/instagram</a
          >.
        </p>
      </div>
    </div>
    <div class="ui row">
      <div class="ui nine wide column" id="brpc">
        <div class="ui header">Site & Mind Map</div>
        <p>
          Brazilian Poker Crew ou BrPC é um time de cashgame com o formato de
          staking.<br />Foi desenvolvido um website onde os jogadores
          interessados podem se inscrever para participar do time e também um
          software interno de estudos baseado em Mind Maps situacionais. Site
          implementado em Angular8 e software de estudo distríbuido desktop via
          Electron Framework.
        </p>
        <p>
          <span class="bold">Saiba mais em:</span>
          <a target="_blank" href="https://brpokercrew.com.br"
            >&nbsp;brpokercrew.com.br</a
          >.
        </p>
      </div>
      <div
        class="ui tablet only computer only middle aligned seven wide column"
      >
        <img
          alt="Logo do BRPC"
          class="ui medium centered image"
          src="/assets/imagens/projetos/brpc.jpg"
        />
      </div>
    </div>
    <div class="ui row">
      <div
        class="ui tablet only computer only middle aligned seven wide column"
      >
        <img
          alt="Logo do Unificado"
          class="ui medium centered image"
          src="/assets/imagens/projetos/unificado.png "
        />
      </div>
      <div class="ui nine wide column" id="calculadora-ufrgs">
        <div class="ui header">Prognóstico Vestibular UFRGS</div>
        <p>
          Prognóstico Vestibular UFRGS é um sistema usado por vestibulandos para
          cálculo de sua média no vestibular da Universidade Federal do Rio
          Grande do Sul.<br />Implementado em Angular8.
        </p>
      </div>
    </div>
    <div class="ui row">
      <div class="ui nine wide column">
        <div class="ui header">JEMS3</div>
        <p>
          O JEMS (Journal and Event Management System) é uma versão adaptada do
          sistema EDAS de Henning Schulzrinne. A versão atual do JEMS mantida
          pela Sociedade Brasileira de Computação é diferente da EDAS original:
          foi adaptada aos costumes e condições locais.<br />Implementado em
          AngularJS, Sass e Django.
        </p>
      </div>
      <div
        class="ui tablet only computer only middle aligned seven wide column"
      >
        <img
          alt="Logo do JEMS 3"
          class="ui medium centered image"
          src="/assets/imagens/projetos/jems.png"
        />
      </div>
    </div>
    <div class="ui row">
      <div
        class="ui tablet only computer only middle aligned seven wide column"
      >
        <img
          alt="Logo do Mammoth Hand Converter"
          class="ui medium centered image"
          src="/assets/imagens/projetos/mammoth.png"
        />
      </div>
      <div class="ui nine wide column" id="mammoth-hand-converter">
        <div class="ui header">Mammoth Hand Converter</div>
        <p>
          Mammoth Hand Converter é um conversor de mãos para jogadores de poker
          que utilizam o software Ignition/Bodog/Bovada e gostariam de ter suas
          sessões revisadas por programas de stats-tracking que não suportam
          essas cardsrooms.<br />Implementado em C#.
        </p>
        <p>
          <span class="bold">Saiba mais em:</span>
          <a target="_blank" href="https://mammothhandconverter.com"
            >&nbsp;mammothhandconverter.com</a
          >.
        </p>
      </div>
    </div>
    <div class="ui row">
      <div class="ui nine wide column">
        <div class="ui header">ScalaWeb</div>
        <p>
          O projeto do Sistema de Comunicação Alternativa para Letramento de
          Pessoas com Autismo surgiu em 2009 com a proposta de apoiar no
          letramento de crianças com autismo. Trata-se de uma plataforma online
          que busca servir de apoio à pessoa que não pratica a oralidade. O
          site, também disponível em inglês e em espanhol, possui uma versão
          para a internet e outra em forma de aplicativo para tablet.
        </p>
      </div>
      <div
        class="ui tablet only computer only middle aligned seven wide column"
      >
        <img
          alt="Logo do SCALA"
          class="ui medium centered image"
          src="/assets/imagens/projetos/scala-web.png"
        />
      </div>
    </div>
    <div class="ui row">
      <div
        class="ui tablet only computer only middle aligned seven wide column"
      >
        <img
          alt="Logo do Mural de Bolsas"
          class="ui medium centered image"
          src="/assets/imagens/projetos/mural-de-bolsas.png"
        />
      </div>
      <div class="ui nine wide column" id="mudal-de-bolsas">
        <div class="ui header">Mural de Bolsas</div>
        <p>
          O Mural de Bolsas é um sistema web desenvolvido por bolsistas da
          Empresa Júnior do Instituto de Informática da Universidade Federal do
          Rio Grande do Sul, visando oferecer um melhor ambiente para a pesquisa
          e cadastro de bolsas de graduação para a comunidade acadêmica.
        </p>
        <p>
          <span class="bold">Saiba mais em:</span>
          <a target="_blank" href="http://ufrgs.br/bolsas"> ufrgs.br/bolsas</a>.
        </p>
      </div>
    </div>
    <div class="ui row">
      <div class="ui nine wide column">
        <div class="ui header">Portal Valentim</div>
        <p>
          O Portal VALENTIM é a ferramenta desenvolvida para uso exclusivo dos
          alunos do professor Ávila Oliveira. O gerenciamento das atividades dos
          alunos é feita de maneira ágil: agendamento de plantões de
          atendimento, simulados e boletins online, downloads, materiais extras
          e controle de redações escritas. Além de outros recursos, a integração
          com serviços de SMS faz do aplicativo uma verdadeira ferramenta no
          auxílio ao aluno, que otimiza o seu estudo, e ao professor Ávila, que
          acompanha as tarefas.
        </p>
        <p>
          <span class="bold">Saiba mais em:</span>
          <a target="_blank" href="https://valentim.avilaprofessor.com.br"
            >&nbsp;valentim.avilaprofessor.com.br</a
          >.
        </p>
      </div>
      <div
        class="ui tablet only computer only middle aligned seven wide column"
      >
        <img
          alt="Logo do Portal Valentim"
          class="ui big centered image"
          src="/assets/imagens/projetos/valentim.png"
        />
      </div>
    </div>
    <div class="ui row">
      <div
        class="ui tablet only computer only middle aligned seven wide column"
      >
        <img
          alt="Astronauta do Planeta ROODA 3"
          class="ui medium centered image"
          src="/assets/imagens/projetos/planeta3.png"
        />
      </div>
      <div class="ui nine wide column" id="planeta-rooda-3">
        <div class="ui header">Planeta ROODA 3</div>
        <p>
          O Planeta ROODA 3 é um ambiente virtual de aprendizagem (AVA) que tem
          como objetivo desenvolver o trabalho coletivo na Internet com alunos e
          professores da Educação Infantil e do Ensino Fundamental. É ambientado
          no espaço sideral e disponibiliza recursos que potencializam a
          aprendizagem, processos comunicacionais e o gerenciamento de turmas.
        </p>
        <p>
          <span class="bold">Saiba mais em:</span>
          <a target="_blank" href="http://nuted.ufrgs.br/planeta3"
            >&nbsp;nuted.ufrgs.br/planeta3</a
          >.
        </p>
      </div>
    </div>
  </div>
</section>
