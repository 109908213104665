<div class="root">
  <app-masterhead></app-masterhead>
  <div class="ui container">
    <app-experiencias></app-experiencias>
    <app-formacoes></app-formacoes>
    <app-projetos></app-projetos>
    <app-idiomas></app-idiomas>
    <app-contato></app-contato>
  </div>
</div>
