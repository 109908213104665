<section class="idiomas">
  <header class="ui dividing header">Meus idiomas:</header>
  <div class="ui large header">
    <img
      class="ui image"
      alt="Bandeira do Brasil"
      src="/assets/imagens/bandeiras/brasil.png"
    />
    <div class="content">
      Língua Portuguesa
      <div class="sub header">Nativo</div>
    </div>
  </div>
  <div class="ui large header">
    <img
      class="ui image"
      alt="Bandeira do Reino Unido"
      src="/assets/imagens/bandeiras/reino-unido.png"
    />
    <div class="content">
      Língua Inglesa
      <div class="sub header">Avançado</div>
    </div>
  </div>
</section>
