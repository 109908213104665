import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { ContatoComponent } from "./components/contato/contato.component";
import { ExperienciasComponent } from "./components/experiencias/experiencias.component";
import { FormacoesComponent } from "./components/formacoes/formacoes.component";
import { IdiomasComponent } from "./components/idiomas/idiomas.component";
import { MasterHeadComponent } from "./components/masterhead/masterhead.component";
import { ProjetosComponent } from "./components/projetos/projetos.component";
import { RootComponent } from "./page/root/root.component";

@NgModule({
  declarations: [
    MasterHeadComponent,
    ContatoComponent,
    ProjetosComponent,
    FormacoesComponent,
    ExperienciasComponent,
    RootComponent,
    IdiomasComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
  ],
  providers: [],
  bootstrap: [RootComponent],
})
export class AppModule {}
