<section class="formacoes">
  <header class="ui dividing header">Minhas formações:</header>
  <div class="ui header">
    <div class="content">
      Ciência da Computação @ Universidade Federal do Rio Grande do Sul.
    </div>
    <div class="sub header">2015 até 2023</div>
  </div>
  <div class="ui header">
    <div class="content">
      Analíse e Desenvolvimento de Sistemas @ SENAI Visconde de Mauá.
    </div>
    <div class="sub header">2010 até 2013</div>
  </div>
  <div class="ui header">
    <div class="content">Ensino Médio @ Colégio Miguel Lampert.</div>
    <div class="sub header">2009 até 2012</div>
  </div>
</section>
