<section class="contato">
  <header class="ui huge header">
    <div class="ui two columns grid">
      <div class="ui six wide column">Interessado? <br />Entre em contato!</div>
      <div class="ui right aligned ten wide column">
        <br />
        ✉ cassiofachinelli<i class="at fitted icon"></i>live.com
      </div>
    </div>
  </header>
  <!-- <div class="ui form" [class.loading]="this.loading" [formGroup]="this.form">
    <div class="two fields">
      <div class="field">
        <label>Nome:</label>
        <input formControlName="name" required type="text" />
      </div>
      <div class="field">
        <label>Email:</label>
        <input formControlName="email" required type="email" />
      </div>
    </div>
    <div class="field">
      <label>Mensagem:</label>
      <textarea formControlName="message" required rows="6"></textarea>
    </div>
    <re-captcha
      formControlName="recaptcha"
      required
      siteKey="6LcaY6UUAAAAACOTVHYxu3nu41_dN3D_56GEWxbo"
    ></re-captcha>
    <br />
    <swal #swal (close)="this.form.get('recaptcha').reset()"></swal>
    <button
      [disabled]="!this.form.valid"
      (click)="this.submit()"
      class="ui primary big button"
      type="submit"
    >
      Enviar
    </button>
  </div> -->
</section>
