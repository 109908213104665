<section class="experiencias">
  <header class="ui dividing header">Minhas experiências:</header>
  <div class="ui stackable grid">
    <div class="ui row">
      <div
        class="ui tablet only computer only three wide middle aligned column"
      >
        <img
          class="ui small centered image"
          src="/assets/imagens/experiencias/thoughtworks-logo.svg"
          alt="Logo do TW"
        />
      </div>
      <div class="ui thirteen wide column">
        <div class="ui header">
          <div class="content">Consultor de software @ Thoughtworks</div>
          <div class="sub header">janeiro/2021 - atualmente</div>
        </div>
      </div>
    </div>
    <div class="ui row">
      <div
        class="ui tablet only computer only three wide middle aligned column"
      >
        <img
          class="ui small centered image"
          src="/assets/imagens/experiencias/brpc.png"
          alt="Logo do BRPC"
        />
      </div>
      <div class="ui thirteen wide column">
        <div class="ui header">
          <div class="content">
            Desenvolvedor de software @ Brazilian Poker Crew
          </div>
          <div class="sub header">junho/2020 - dezembro/2020</div>
        </div>
        <p>
          Desenvolvedor responsável pelo desenvolvimento de ferramentas de
          estudo voltadas ao Cash Game online como PioTools. Uso de tecnologias
          como C#, Electron, Angular, Typescript e MySQL.
        </p>
      </div>
    </div>
    <div class="ui row">
      <div
        class="ui tablet only computer only three wide middle aligned column"
      >
        <img
          class="ui tiny centered image"
          src="/assets/imagens/experiencias/networks.png"
          alt="Logo do Grupo de Redes UFRGS"
        />
      </div>
      <div class="ui thirteen wide column">
        <div class="ui header">
          <div class="content">
            Desenvolvedor de software @ Grupo de Redes - UFRGS
          </div>
          <div class="sub header">maio/2018 até atualmente</div>
        </div>
        <p>
          Desenvolvedor fullstack da terceira versão da plataforma JEMS. Uso de
          padrões de projeto, CI/CD, pair programming. Tecnologias utilizadas
          foram Angular8 & Python (Django Framework), além do uso de Docker para
          cada ambiente de desenvolvimento.
        </p>
      </div>
    </div>
    <div class="ui row">
      <div
        class="ui tablet only computer only three wide middle aligned column"
      >
        <img
          class="ui small centered image"
          src="/assets/imagens/experiencias/cinted.png"
          alt="Logo do CINTED"
        />
      </div>
      <div class="ui thirteen wide column">
        <div class="ui header">
          <div class="content">Desenvolvedor de software @ CINTED</div>
          <div class="sub header">dez/2016 até junho/2018</div>
        </div>
        <p>
          Responsável pela adaptação do novo design responsivo, refatoração e
          adaptação de códigos legados e aprimoramentos em padrões de projeto.
        </p>
      </div>
    </div>
    <div class="ui row">
      <div
        class="ui tablet only computer only three wide middle aligned column"
      >
        <img
          class="ui tiny centered image"
          src="/assets/imagens/experiencias/ide.png"
          alt="Logo da IDE"
        />
      </div>
      <div class="ui thirteen wide column">
        <div class="ui header">
          <div class="content">
            Desenvolvedor de software @ Empresa Júnior IDE
          </div>
          <div class="sub header">dez/2015 até jul/2016</div>
        </div>
        <p>
          Líder do projeto de desenvolvimento do novo sistema web de cadastro e
          pesquisa de bolsas acadêmicas da UFRGS, o
          <b><a href="#mudal-de-bolsas">Mural de Bolsas</a></b
          >. Desenvolvido em PHP com banco de dados em MySQL, o sistema oferece
          um sistema de busca de bolsas com multíplos filtros substituindo o
          obsoleto mural de bolsas da universidade.
        </p>
      </div>
    </div>
    <div class="ui row">
      <div
        class="ui tablet only computer only three wide middle aligned column"
      >
        <img
          class="ui tiny centered image"
          src="/assets/imagens/experiencias/nuted.png"
          alt="Logo do nuted"
        />
      </div>
      <div class="ui thirteen wide column">
        <div class="ui header">
          <div class="content">Desenvolvedor de software @ NUTED</div>
          <div class="sub header">ago/2014 até ago/2016</div>
        </div>
        <p>
          Desenvolvimento da terceira versão do ambiente virtual usado pelos
          doutorandos do Núcleo de Tecnologia Digital Aplicada à Educação
          (NUTED), o <b><a href="#planeta-rooda-3">Planeta ROODA</a></b
          >. As tecnologias usadas no desenvolvimento das ferramentas foram
          HTML5/JS/CSS3 no front-end e PHP com MySQL na parte servidor.
        </p>
      </div>
    </div>
  </div>
</section>
